<template>
  <a-modal
    :visible="visible"
    title="Tambah Program Toko"
    @ok="handleOk"
    @cancel="handleCancel"
    width="90%"
    :destroy-on-close="true"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mt-2"
        />
        <a-button class="m-2 mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        :columns="columns"
        :data-source="dataToko"
        size="small"
        row-key="code"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: totalCount,
          pageSize: perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #formatDate="{text}">
          <span>{{ text ? moment(text).format('DD MMMM YYYY') : '' }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#108ee9">Aktif</a-tag>
          <a-tag v-else color="grey">Tidak Aktif</a-tag>
        </template>
      </md-table>
    </div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
  <a-modal
    v-if="itemDelete.show"
    v-model:visible="itemDelete.show"
    title="Catatan"
    :closable="false"
    :mask-closable="false"
    @ok="submitReason"
  >
    <p>Alasan menghapus toko dari program?</p>
    <a-input v-model:value="itemDelete.note"></a-input>
    <a-alert v-if="itemDelete.error" :message="itemDelete.error" type="error" />
    <template #footer>
      <a-button key="submit" type="primary" @click="submitReason">OK</a-button>
    </template>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRef, toRefs, ref, computed, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { bcolumns } from './columns'
import { message } from 'ant-design-vue'
import { serialize } from 'object-to-formdata'
import moment from 'moment'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}
export default defineComponent({
  components: {},
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        programId: null,
        toko: [],
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const state = reactive({
      dataToko: [],
      isFetching: false,
      q: null,
      params: [],
      selectedRowKeys: [],
      selectedRows: [],
      note: null,
    })

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const startRow = ref(1)

    const errorMessage = ref(null)
    const columns = ref([])
    columns.value = bcolumns

    const itemDelete = reactive({
      show: false,
      item: [],
      note: '',
      error: null,
    })

    const submitReason = () => {
      if (itemDelete.note === null || itemDelete.note.trim() === '') {
        itemDelete.error = 'Alasan tidak boleh kosong!'
        return
      } else {
        itemDelete.error = null
      }

      state.note = itemDelete.note
      itemDelete.show = false
      itemDelete.item = []
      itemDelete.note = ''

      submit()
    }

    const handleChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
    }

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchDataToko()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const onSelectChange = (selectedRowKeys, selectedRows, dataIndex) => {
      const unselect = state.selectedRowKeys.filter(x => !selectedRowKeys.includes(x))
      if (unselect.length > 0) {
        unselect.forEach(item => {
          itemDelete.item.push(item)
        })
      }

      state.selectedRowKeys = selectedRowKeys
      state.selectedRows = selectedRows
      const difference = itemDelete.item.filter(el => !state.selectedRowKeys.includes(el))
      itemDelete.item = difference
    }

    onMounted(() => {
      state.selectedRowKeys = props.model.toko
      fetchDataToko()
    })

    const fetchDataToko = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        active: true,
        q: state.q,
      }
      state.params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/customers', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          state.dataToko = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const handleOk = async () => {
      if (itemDelete.item.length > 0) {
        itemDelete.show = true
      } else {
        submit()
      }
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    const submit = async () => {
      const form_data = serialize(
        {
          program: props.model.programId,
          toko: state.selectedRowKeys,
          note: state.note,
        },
        { indices: true },
      )

      const url = `/api/loyalty-program-stores`
      apiClient
        .post(url, form_data)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form_data, true)
        })
        .catch(async error => {
          message.error('Gagal menyimpan')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    const search = () => {
      fetchDataToko()
    }

    return {
      emit,
      ...toRefs(state),
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      startRow,
      columns,
      rowSelection,
      onSelectChange,
      handleOk,
      handleCancel,
      handleChange,
      handleTableChange,
      errorMessage,
      fetchDataToko,
      search,
      moment,
      itemDelete,
      submitReason,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
