<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
         <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mt-2"
        />
        <a-button class="m-2 mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          title="Tambah Toko"
          type="primary"
          @click="showModal"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <!-- <a-button
          class="ml-2"
          title="download excel"
          type="primary"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button> -->
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #formatDate="{text}">
          <span>{{ text ? moment(text).format('DD-MM-yyyy') : '' }}</span>
        </template>
        <template #status="{ text }">
          <a-tag v-if="text" color="#5cb85c">Aktif</a-tag>
          <a-tag v-else color="#fd6464">Tidak Aktif</a-tag>
        </template>
      </md-table>
    </div>
    <m-modal
      v-if="visible"
      v-model:visible="visible"
      :model="model"
      @handleOk="handleOk"
      :destroy-on-close="true"
    ></m-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import MModal from './modal'
import acolumns from './columns'
import qs from 'qs'
import moment from 'moment'
import apiClient from '@/services/axios'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: { MModal },
  emits: ['handleOk'],
  setup() {
    const state = reactive({
      data: [],
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
      page: 1,
      isFetching: false,
      isDownloading: false,
      search: null,
      q: null,
      params: [],
    })

    const route = useRoute()
    const visible = ref(false)

    const handleChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
    }

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const startRow = ref(1)

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const model = reactive({ readOnly: false })

    const showModal = () => {
      model.programId = route.params.id
      model.toko = state.data.map(function (item) {return item?.code}) ?? []
      visible.value = true
    }

    const handleOk = form => {
      visible.value = false
      fetchData()
    }

    const errorMessage = ref()

    const columns = ref([])
    columns.value = acolumns

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        q: state.q,
      }
      state.params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get(`/api/list-store/${route.params.id}/assign`, {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          state.meta = _meta || { pageCount: 0, totalCount: 0 }
          state.pageCount = state.meta.pageCount
          state.totalCount = state.meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      startRow,
      visible,
      showModal,
      handleOk,
      columns,
      handleChange,
      handleTableChange,
      model,
      ...toRefs(state),
      search,
      errorMessage,
      moment,
    }
  },
})
</script>
<style scoped>
.card-body {
  margin-left: 20px;
}
.button {
  margin: 0 3px;
}
</style>
