const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Tanggal Registrasi',
    dataIndex: 'tanggal_registrasi',
  },
  {
    title: 'Kode Toko',
    dataIndex: 'code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'name',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
  },
  {
    title: 'No Telepon',
    dataIndex: 'phone',
  },
  {
    title: 'Nama Pemilik Toko',
    dataIndex: 'owner',
  },
  {
    title: 'Status Toko',
    dataIndex: 'active',
    slots: { customRender: 'status' },
  },
  {
    title: 'Area Toko',
    dataIndex: 'area_name',
  },
  {
    title: 'Region Toko',
    dataIndex: 'region_name',
  },
]

export const bcolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Tanggal Registrasi',
    dataIndex: 'registration_at',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'Kode Toko',
    dataIndex: 'code',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'name',
  },
  {
    title: 'Alamat',
    dataIndex: 'address',
  },
  {
    title: 'No Telepon',
    dataIndex: 'phone',
  },
  {
    title: 'Nama Pemilik Toko',
    dataIndex: 'owner',
  },
  {
    title: 'Status Toko',
    dataIndex: 'active',
    slots: { customRender: 'status' },
  },
  {
    title: 'Area Toko',
    dataIndex: 'area',
  },
  {
    title: 'Region Toko',
    dataIndex: 'region',
  },
]

export default acolumns
